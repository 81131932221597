.section-blog {
  max-width: 720px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #94979e;
}
.header-blog {
  text-align: center;
  margin-bottom: 96px;
}
.header-blog_title {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;
  color: #ffffff;
}
.header-blog_time {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  margin-bottom: 12px;
}
.header-blog_description {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.blog_title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 48px;
  color: #ffffff;
  .blog_link {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 48px;
  }
}
.blog_sub-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: #e0e3e6;
  margin-bottom: 24px;
}
.blog_description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.blog_description.blog_description--secondary {
  color: #ffffff;
}
.blog_link {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #38bcf7;
  transition: color 0.3s linear;
}
.blog_link:hover {
  color: #7dd2fb;
}
.blog_img-wrap {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.blog_img-wrap-width-auto {
  width: max-content;
  img {
    width: auto;
  }
}
.blog_list {
  list-style: initial;
  padding-left: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  li {
    margin-bottom: 8px;
    padding-left: 10px;
  }
  li::marker {
    color: #94979e;
    text-indent: -20px !important;
  }
}
.blog_list-ol {
  list-style-type: decimal;
}
.blog_code {
  width: 494px;
  overflow: hidden;
  padding: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1b1e25;
}
.blog_caption_img {
  display: flex;
  align-items: center;
  color: #61646b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 1rem;
  img {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
}
b.secondary {
  color: #ffffff;
}
.margin-b-12 {
  margin-bottom: 12px;
}
.margin-b-16 {
  margin-bottom: 16px;
}
.margin-b-20 {
  margin-bottom: 20px;
}
.margin-b-24 {
  margin-bottom: 24px;
}
.margin-b-32 {
  margin-bottom: 32px;
}
.margin-b-40 {
  margin-bottom: 40px;
}
.margin-b-48 {
  margin-bottom: 48px;
}
.margin-b-56 {
  margin-bottom: 56px;
}
@media screen and (max-width: 991px) {
  .blog_img-wrap-width-auto {
    width: auto;
  }
  .header-blog {
    margin-bottom: 64px;
  }
  .blog_code {
    width: 100%;
    padding: 16px;
    font-size: 10px;
  }
  .header-blog_title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
  }
  .header-blog_time {
    font-size: 16px;
  }
  .header-blog_description {
    font-size: 18px;
    line-height: 28px;
  }

  .blog_title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 36px;

    .blog_link {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 36px;
    }
  }
  .blog_sub-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
  }
  .blog_description {
    font-size: 14px;
    line-height: 20px;
  }
  .blog_list {
    font-size: 14px;
    line-height: 20px;
  }
  .blog_link {
    font-size: 14px;
    line-height: 20px;
  }

  .margin-b-12 {
    margin-bottom: 8px;
  }
  .margin-b-16 {
    margin-bottom: 12px;
  }
  .margin-b-20 {
    margin-bottom: 16px;
  }
  .margin-b-24 {
    margin-bottom: 20px;
  }
  .margin-b-32 {
    margin-bottom: 24px;
  }
  .margin-b-40 {
    margin-bottom: 32px;
  }
  .margin-b-48 {
    margin-bottom: 36px;
  }
  .margin-b-56 {
    margin-bottom: 40px;
  }
}
