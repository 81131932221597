.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 48px;
  height: 48px;
  color: #94979e;
  border: 1px solid #1b1e25;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0;
  .p-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-icon {
    width: 24px;
    height: 24px;
  }
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #ffffff;
  border-color: #069cdf;
  background: #069cdf;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #ced4da;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #adb5bd;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #eef2ff;
  color: #4338ca;
}
//Custom
.p-carousel {
  position: relative;
}
.p-carousel-header {
  position: absolute;
}
.p-carousel-content {
  width: calc(100% + 232px);
}
.p-carousel-container {
  position: relative;
  padding-top: 96px;
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  position: absolute;
  top: 0;
  right: 232px;
}
.p-carousel .p-carousel-content .p-carousel-prev {
  right: 304px;
}
@media screen and (max-width: 1650px) {
  .p-carousel-content {
    width: calc(100% + 100px);
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    right: 100px;
  }
  .p-carousel .p-carousel-content .p-carousel-prev {
    right: 172px;
  }
}
@media screen and (max-width: 1400px) {
  .p-carousel-content {
    width: calc(100% + 50px);
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    right: 50px;
  }
  .p-carousel .p-carousel-content .p-carousel-prev {
    right: 122px;
  }
}
@media screen and (max-width: 991px) {
  .p-carousel-header {
    position: relative;
    .title-additional {
      font-size: 30px;
      margin-bottom: 12px;
    }
  }
  .p-carousel-content {
    width: calc(100% + 16px);
    margin: 0 -8px;
  }
  .p-carousel-container {
    position: relative;
    padding-top: 0;
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 24px;
    height: 24px;
    position: relative;
    .p-icon {
      width: 16px;
      height: 16px;
    }
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    position: relative;
    right: 0;
    left: initial;
    z-index: 999;
  }
  .p-carousel .p-carousel-content .p-carousel-prev {
    position: relative;
    right: initial;
    left: 0px;

    z-index: 999;
    top: 50%;
  }
}
