.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}
.page-card {
  border-radius: 8px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 1rem;
}
.page-card-title {
  color: var(--neutral-800, #1e293b);
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  margin-bottom: 24px;
}
.page-card-subtitle {
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  color: #667085;
}
.invisible {
  visibility: hidden;
}
.wrapper {
  display: flex;
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
}
.layout-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #03060d;
  color: #ffffff;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.content {
  margin-top: 76px;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}
.content-menu {
  display: flex;
  position: fixed;
  left: 0;
  border-bottom: 1px solid #1b1e25;
  z-index: 99;
  background: #03060d;
  width: 100%;
  height: 76px;
  .container-menu {
    display: flex;
    align-items: center;
    max-width: 1280px;
    padding: 0 32px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
  .mobile-menu {
    visibility: hidden;
    display: none;
    margin: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.desktop-menu {
  display: flex;
  margin-left: auto;
  align-items: center;
  &__item {
    padding: 8px 12px;
    cursor: pointer;
    color: #e0e3e6;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    transition: all 0.3s linear;
    display: inline-flex;
    height: max-content;
  }
  &__button {
    margin-left: 20px;
  }
}
.desktop-menu__item:hover {
  color: #fff;
}
.desktop-menu--active {
  color: #b54708;
}
.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background-color: var(--maskbg);
}

.mobile-menu-wrap {
  transform: translate(-100%);
  left: 0;
  transition: all 0.3s linear;
  position: absolute;
  width: 70%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  background: #272a31;
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
}
.overflow-auto {
  overflow: auto;
}
html {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  /* chrome based */
  width: 0; /* ширина scrollbar'a */
  height: 0;
  background: transparent; /* опционально */
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  position: relative;
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: #45484f;
  border-radius: 8px;
}

@media screen and (max-width: 991px) {
  .content {
    margin-top: 72px;
  }
  .desktop-menu {
    visibility: hidden;
    display: none;
  }
  .show-menu {
    .desktop-menu {
      visibility: visible;
      display: flex;
      margin-left: 16px;
      .desktop-menu__button {
        margin: 0;
        margin-top: 8px;
      }
    }
  }
  .content-menu {
    position: fixed;
    justify-content: space-between;
    z-index: 99;
    height: 72px;
    .container-menu {
      padding: 0 16px;
    }
    .mobile-menu {
      visibility: visible;
      display: inline-flex;
      position: absolute;
      right: 8px;
    }
    .desktop-menu {
      display: none;
    }
  }

  .layout-wrapper.show-menu {
    position: relative;
    .mobile-menu-wrap {
      transform: translate(0);
      .desktop-menu {
        flex-direction: column;
      }
    }
    .layout-mask {
      display: block;
      animation: fadein 0.2s;
    }
  }
}
