.badge-wrap {
  display: flex;
}
.table-badge {
  display: inline-flex;
  padding: 2px 12px;
  color: #c9cdd2;
  background: #232730;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.table-badge.info {
  background: #20353f;
  color: #38bcf7;
}
.table-badge.success {
  background: #1e2d2b;
  color: #40d86e;
}
.column-description {
  width: 235px;
}
.column-tag {
  width: 65px;
}
.column-value {
  width: 215px;
}
.custom-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 6px;
}
.table-wrap {
  display: flex;
  flex-direction: column;
  height: max-content;
  background: #0d1017;
  border-radius: 16px;
  position: relative;
}
.custom-table {
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: inherit;
  .primary-tr {
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #e0e3e6;
    }
  }
  tr {
    border-bottom: 1px solid #272a31;
    transition: all 0.3s linear;
    th {
      text-align: left;
      padding: 1rem;
      color: #94979e;
      font-weight: 400;
    }
    td {
      text-align: left;
      padding: 1rem;
    }
  }
  tr.active-row:hover {
    background: #1b1e25;
    cursor: pointer;
  }
  tbody {
    tr:last-child {
      border-bottom: none;
    }
  }
}
.custom-table--exchanges {
  tbody {
    tr {
      td {
        padding: 0;
        a {
          text-align: left;
          padding: 1rem;
          display: flex;
          align-items: center;
        }
      }
    }
    tr:last-child {
      border-bottom: 1px solid #272a31;
    }
    tr.last-tr {
      border-bottom: none;
    }
  }
  .exchanges-icon {
    max-height: 28px;
  }
}
@media screen and (max-width: 991px) {
  .custom-table--exchanges {
    thead tr {
      display: none;
    }
    tbody,
    tr {
      display: grid;
      grid-template-columns: 1fr;
    }
    tbody {
      tr {
        td {
          a {
            padding: 4px 16px;
          }
          a.last-cells {
            padding-bottom: 24px;
          }
          a.first-cells {
            padding-top: 24px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
  .column-description {
    width: auto;
  }
  .column-tag {
    width: auto;
  }
  .column-value {
    width: auto;
  }
}
