*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3 {
  margin: 0;
  font: inherit;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: #ab7ecf;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

button,
input,
select,
textarea {
  font: inherit;
  background-color: transparent;
  outline: none;
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
