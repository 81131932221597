.section {
  margin: 96px 0;
}
.section-home-header {
  background-color: transparent;
  background-repeat: no-repeat;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.73%, #0d1017 100.73%),
    url('/assets/images/home-bg.jpg') lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px, 0px);
  z-index: 20;
  visibility: inherit;
  position: inherit;
  height: calc(100vh - 76px);
  .container {
    height: 100%;
  }
  .wrapp-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    max-width: 747px;
  }
  .form-wrap {
    position: relative;
    margin-top: 48px;
    max-width: 560px;
    .custom-input {
      height: 52px;
    }
    .button {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.support-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 60px;
  .support-item {
    width: 25%;
    margin-bottom: 48px;
  }
}
.secttion-link {
  color: #38bcf7;
  transition: color 0.3s linear;
}
.secttion-link:hover {
  color: #7dd2fb;
}
app-supported-exchanges {
  app-title-section {
    .wrapp {
      margin-bottom: 0 !important;
    }
  }
}
.card-hover {
  cursor: pointer;
  .img-wrap {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: all 0.3s linear;
  }
}
.card-hover:hover {
  .secttion-link {
    color: #7dd2fb;
  }
  .img-wrap {
    border: 1px solid #069cdf;
  }
}
.properties {
  margin: 64px 0;
  padding: 48px;
  border-radius: 16px;
  background: #0d1017;
  border: 1px solid #272a31;
  .properties-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }
  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
app-supported-exchanges {
  .properties {
    padding: 0;
    background: inherit;
    border: none;
  }
}
app-home,
app-subscribe-newsletter {
  .error-message {
    position: absolute;
    left: 0;
    bottom: -32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #f86f6e;
    display: flex;
    align-items: center;
    i {
      color: #f86f6e;
      margin-right: 8px;
    }
  }
  .notification {
    position: absolute;
    bottom: -32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    .notification-text.success {
      color: #40d86e;
    }
    .notification-icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      max-width: 16px;
      min-width: 16px;
    }
  }
}
app-subscribe-newsletter {
  .error-message {
    bottom: initial;
    top: -24px;
  }
}

@media screen and (max-width: 991px) {
  app-home {
    .error-message {
      bottom: initial;
      top: -24px;
    }
  }
  .section {
    margin: 64px 0;
  }
  .section-home-header {
    height: calc(100vh - 72px);
    .wrapp-info {
      max-width: 100%;
    }
    .form-wrap {
      max-width: 100%;
      .custom-input {
        height: 48px;
      }
      .button {
        position: relative;
        right: initial;
        top: initial;
        transform: initial;
        height: 48px;
        width: 100%;
        margin-top: 12px;
      }
    }
  }
  .support-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    .support-item {
      width: 50%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      overflow: hidden;
      padding: 6px 16px;
      img {
        height: 100%;
      }
    }
  }
  .properties {
    margin: 48px 0;
    .properties-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .button-wrap {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        width: 100%;
      }
      .margin-group-right {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}
