.title {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: 20px;
}
.title-secondary {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 16px;
}

.title-additional {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}
.title-card {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.title-card-learn {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.title-big-card {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.title-24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #94979e;
}
.description-secondary {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #94979e;
}
.form-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #94979e;
}
.divider {
  width: 100%;
  height: 1px;
  background: #1b1e25;
  margin: 24px 0;
}
.margin-group-right {
  margin-right: 12px;
}
.margin-bottom-12 {
  margin-bottom: 12px;
}
.accordeon-list {
  list-style: initial;
}
.accordeon-item {
  border: 1px solid #1b1e25;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  background: #03060d;
  border-radius: 10px;
  transition: box-shadow 0.2s;
  margin-bottom: 8px;
  .accordeon-header {
    position: relative;
    padding: 1.25rem;
    padding-right: 3rem;
  }
  .accordeon-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .accordeon-content {
    padding: 1.25rem;
    padding-right: 3rem;
    font-weight: 400;
    color: #94979e;
  }
}
.section-wrap {
  max-width: 720px;
  margin: 0 auto;
  .list-item {
    display: flex;
    margin-bottom: 8px;

    .icon-wrap {
      width: 24px;
      height: 24px;
      min-width: 24px;
      margin-right: 12px;
    }
    .list-item-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 991px) {
  .title {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 16px;
  }
  .title-secondary {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 32px;
  }
  .margin-group-right {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
