.custom-input {
  color: #fff;
  border: 1px solid #272a31;
  height: 48px;
  padding: 12px 16px;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  transition: all 0.3s linear;
  background: rgba(39, 42, 49, 0.24);
}
.custom-input::placeholder {
  color: #61646b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.custom-input:focus,
.custom-input:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #e0e3e6;
}
.custom-input.invalid {
  border-color: #d92d20;
}
.search-input {
  width: 450px;
  padding-left: 3rem;
}
.custom-input-textarea {
  height: auto;
}
.error-icon-wrap {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
  }
}
.input-icon-left {
  position: relative;
  img {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
app-contact-us,
app-access-demo-environment {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 96px;
  }
  .description-secondary {
    margin-bottom: 48px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form {
    border-radius: 16px;
    border: 1px solid #272a31;
    background: #0d1017;
    padding: 32px;
  }
  .notification {
    flex-grow: 1;
    margin-right: auto;
    max-width: 300px;
    display: flex;
    align-items: center;
  }
  .notification-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .notification-text.success {
    color: #40d86e;
  }
  .notification-icon {
    margin-right: 1rem;
    width: 32px;
    height: 32px;
    max-width: 32px;
    min-width: 32px;
  }
  .input-wrap {
    position: relative;
    margin-bottom: 40px;
    margin-top: 8px;
    .custom-input {
      padding-left: 42px;
    }
    .custom-input-textarea {
      padding-left: 16px;
    }
    .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      display: inline-flex;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .error-message {
      position: absolute;
      left: 0;
      bottom: -32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #f86f6e;
      display: flex;
      align-items: center;
      i {
        color: #f86f6e;
        margin-right: 8px;
      }
    }
  }
}
.radio-non-display {
  display: none;
}
.radio-btn {
  padding: 24px;
  border: 1px solid #03060d;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  .img-wrap {
    width: 64px;
    height: 64px;
    min-width: 64px;
    max-width: 64px;
    overflow: hidden;
    margin-right: 16px;
    background-image: url('/assets/icons/no-check-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.radio-btn:hover {
  border: 1px solid #069cdf;
}
.radio-btn:not(:last-child) {
  margin-bottom: 16px;
}
.radio-non-display:checked + .radio-btn {
  border: 1px solid #069cdf;
  .img-wrap {
    background-image: url('/assets/icons/check-icon.svg');
  }
}
@media screen and (max-width: 991px) {
  .custom-input {
    background: rgba(39, 42, 49, 0.16);
  }
  .search-input {
    width: 100%;
  }
  app-contact-us,
  app-access-demo-environment {
    .grid {
      grid-template-columns: repeat(1, 1fr);
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      grid-row-gap: 48px;
    }
  }
}
