.button-wrap-center {
  display: flex;
  justify-content: center;
}

.btn,
.btn-secondary,
.btn-outline {
  color: #fff;
  background: #069cdf;
  border: 1px solid #069cdf;
  padding: 0.6rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.25rem;
  transition: all 0.3s linear;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  height: 48px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:hover {
  background: #0283c5;
  color: #fff;
  border-color: #0283c5;
}
.btn:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #91c5f5, 0 1px 2px 0 #000;
}

.btn-secondary {
  color: #0d1017;
  background: #fff;
  border: 1px solid #fff;
}
.btn-secondary:hover {
  background: #f7f7f8;
  color: #0d1017;
  border-color: #f7f7f8;
}
.btn-secondary:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 #000;
}

.btn-outline {
  background-color: transparent;
  color: #e0e3e6;
  border: 1px solid rgba(148, 151, 158, 0.32);
}
.btn-outline:hover {
  background-color: transparent;
  color: #94979e;
  border: 1px solid #f7f7f8;
}
.btn-outline:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 #000;
}

@media screen and (max-width: 991px) {
  .mobile-full-button {
    width: 100%;
  }
  .button-wrap-center {
    flex-direction: column;
    justify-content: initial;
  }
  .mobile-mb {
    margin-bottom: 5px;
  }
}
