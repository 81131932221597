.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-center {
  justify-content: center !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.relative {
  position: relative !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 2rem !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}
.text-center {
  text-align: center !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
