.big-card {
  height: 434px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  .title-big-card {
    margin-bottom: 16px;
  }
  .description {
    margin-bottom: 40px;
  }
  .image-wrap {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }

  .info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: auto;
    .list-item {
      display: flex;
      margin-bottom: 8px;

      .icon-wrap {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin-right: 12px;
      }
      .list-item-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

.big-card.odd {
  .info-wrap {
    grid-column: 1;
    order: -1;
  }
}
.fix-code-card {
  display: flex;
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  padding: 1rem;
  background: #1c1e26;
  border-radius: 1rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  height: 100%;
  overflow: hidden;
  min-height: 434px;
  height: 434px;
  .content-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    .fixmsg {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      .key {
        color: #f86f6e;
      }
      .val {
        color: #38bcf7;
      }
      .sep {
        color: #e0e3e6;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .big-card.odd {
    .info-wrap {
      grid-column: 1;
      order: 1;
    }
  }
  .big-card {
    height: max-content;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 48px;
    .description {
      margin-bottom: 32px;
    }
  }
}
