app-footer {
  margin-top: auto;
}
.section-subscribe {
  margin: 64px 0;
}
.section-footer {
  padding: 64px 0;
  background: #0d1017;
  .footer-divider {
    width: 100%;
    height: 1px;
    background: #1b1e25;
    margin: 32px 0;
  }
  .copyright {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #94979e;
  }
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .desktop-menu__item {
    padding: 8px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #94979e;
    transition: all 0.3s linear;
  }
  .desktop-menu__item:last-child {
    padding-right: 0;
  }
  .desktop-menu__item:hover {
    color: #fff;
  }
}
.footer-menu--privacy {
  .desktop-menu__item {
    padding: 0;
  }
}
.footer__socials__wrap {
  display: flex;
  justify-content: space-between;
}
.footer__socials {
  display: flex;
  align-items: center;
  .social__link {
    margin-right: 20px;
  }
}
.hiden-footer-subscribe {
  .section-subscribe {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .section-subscribe {
    margin: 48px 0;
  }
  .section-footer {
    padding: 48px 0;
  }
  .footer-menu {
    flex-direction: column;
    align-items: flex-start;
    .footer-menu-list {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
    }
    .desktop-menu__item {
      width: 50%;
      padding-left: 0;
    }
  }
  .footer__socials__wrap {
    flex-direction: column;
  }
  .footer__socials {
    margin-bottom: 24px;
  }
  .copyright {
    margin-bottom: 24px;
  }
}
